<template>
  <v-app class="bg-green-lighten-5">
    <Appbar />
    <v-main>
      <router-view/>
    </v-main>
    <AppFooter />
  </v-app>
</template>

<script>
import Appbar from '@/components/AppBar.vue';
import AppFooter from '@/components/AppFooter.vue';
export default {
  name: 'App',

  components: {
    Appbar,
    AppFooter
  },

  data: () => ({
    //
  }),
}
</script>

<style scoped>

</style>

