<template>
  <v-main>
    <v-sheet
      class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4"
      elevation="4"
      height="250"
      rounded
      max-width="800"
      width="100%"
    >
      <div>
        <h2 class="text-h4 font-weight-black text-black">Georgia Global Spice LTD</h2>
        <br><br>
        <div class="text-h5 font-weight-medium mb-2">
            Georgia, City Samtredia, Rioni str., N2, N4
        </div>
  
      </div>
    </v-sheet>
  </v-main>
  </template>