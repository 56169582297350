<template>
  <v-sheet height="8" class="bg-transparent"></v-sheet>
  <v-card flat max-width="1024" class="mx-auto bg-transparent px-5">
    <v-carousel cycle="3" hide-delimiters height="420">
      <v-carousel-item v-for="slide in slides" :key="slide.id" :src="require(`@/assets/products/${slide.image}`)"
        cover></v-carousel-item>
    </v-carousel>
    <br>
    <v-layout>
      <v-row justify="center">
        <v-col v-for="item in items" :key="item.id" cols="6" sm="4" align-self-center>
          <v-card height="380" class="bg-green-lighten-4" elevation="5">
            <v-img class="w-100" height="240" cover  :src="require(`@/assets/products/${item.image}`)"></v-img>
            <v-card-title class="text-uppercase">
              {{ item.title }}
            </v-card-title>
            <v-card-subtitle v-if="item.type != ''">
              {{ item.type }}
            </v-card-subtitle>
            <v-card-subtitle v-if="item.weight != ''">
              Packing: {{ item.weight + " " + item.packing }}
            </v-card-subtitle>
            <v-card-subtitle v-if="item.color != ''">
              Color: {{ item.color }}
            </v-card-subtitle>
            <v-card-subtitle v-if="item.size != ''">
              Size: {{ item.size }}
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
    <br>

    <div class="d-flex" v-for="img in trucks" :key="img.id">
        <v-card class="w-100 h-100 my-5" height="400" elevation="5">
          <v-parallax :src="require(`@/assets/products/${img.image}`)"></v-parallax>
        </v-card>
    </div>
    
  </v-card>
  <br>
</template>

<script>
import { defineComponent } from 'vue';

// Components

export default defineComponent({
  name: 'HomeView',
  components: {

  },

  data() {
    return {
      items: [
        { id: 1, title: "laurel leaves", image: "handselect.jpeg", type: "HAND SELECT", weight: "10/20 Kgs", packing: "Carton Boxes", color: "Green", size: "" },
        { id: 2, title: "laurel leaves", image: "semi.jpg", type: "HAND SEMI SELECT", weight: "10/20 Kgs", packing: "Carton Boxes", color: "Green", size: "" },
        { id: 3, title: "laurel leaves", image: "aaa.jpg", type: "HAND SEMI SELECT AAA", weight: "50 kgs", packing: "Pressed Bales", color: "Green", size: "" },
        { id: 4, title: "laurel leaves", image: "aa.jpg", type: "HAND SEMI SELECT AA", weight: "50 kgs", packing: "Pressed Bales", color: "Green", size: "" },
        { id: 5, title: "laurel leaves", image: "a.jpg", type: "HAND SEMI SELECT A", weight: "50 kgs", packing: "Pressed Bales", color: "Green", size: "" },
        { id: 6, title: "laurel leaves cut", image: "cut05.jpg", type: "", weight: "", packing: "", color: "", size: "0.5 MM - 1.5 MM" },
        { id: 7, title: "laurel leaves cut", image: "cut15.jpg", type: "", weight: "", packing: "", color: "", size: "1.5 MM - 5.5 MM" },
        { id: 8, title: "laurel leaves cut", image: "cut55.jpg", type: "", weight: "", packing: "", color: "", size: "5.5 MM - 9.5 MM" },
      ],
      slides: [
        { id: 1, image: "branch0.jpeg" },
        { id: 2, image: "branch1.jpeg" },
        { id: 3, image: "branch2.jpeg" },
        { id: 4, image: "branch3.jpeg" },
      ],
      trucks: [
        { id: 1, image: "leaf1.jpeg" },
        { id: 1, image: "leaf2.jpeg" },
        { id: 1, image: "truck1.jpeg" },
        { id: 2, image: "truck2.jpeg" },
        { id: 3, image: "truck3.jpeg" },
        { id: 4, image: "truck4.jpeg" },
        { id: 5, image: "truck5.jpeg" },
        { id: 6, image: "truck6.jpeg" },
        { id: 7, image: "truck7.jpeg" },
        { id: 8, image: "truck8.jpeg" },
        { id: 9, image: "truck9.jpeg" },
        { id: 10, image: "truck10.jpeg" },
      ]
    }
  },
});
</script>
