<template>
    <v-app-bar>
        <v-toolbar class="bg-green-darken-2 text-white">
            <v-app-bar-title class="headline text-uppercase font-weight-bold">Global Spice</v-app-bar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-sm-and-down">
                <v-btn flat v-on:click="navigateToClientList">Home</v-btn>
                <v-btn flat >About Us</v-btn>
                <v-btn flat v-on:click="navigateToContacts">Contacts</v-btn>
            </v-toolbar-items>
        </v-toolbar>
    </v-app-bar>
</template>
<script>
export default {
    methods: {
        navigateToClientList() {
            this.$router.push({ name: 'home' })
        },
        navigateToContacts() {
            this.$router.push({ name: 'contacts' })
        }
    },
}
</script>